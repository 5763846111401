import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {
  mainWhite,
  mainBlack,
  mainGray,
  graphik,
  larsseit,
  amatic,
  screen,
  transHover,
} from "../../components/common/variables";
import { Waypoint } from "react-waypoint";
import ScrollHint from "../../components/scroll-hint";
import ImgTestimonialBg from "../../images/case-studies/apw-partners/svg/apw-testimonial-bg.svg";
import LabeledArrow from "../../components/labeled-arrow";
import websiteVideo from "../../videos/handsome-creative-apw-partners.mp4";
import bookGif from "../../images/case-studies/apw-partners/handsome-creative-apw-book-mockup-pageturn.gif";
import ArrowCurved from "../../images/case-studies/apw-partners/svg/curved-arrow.svg";
import ArrowSemiCurved from "../../images/case-studies/apw-partners/svg/semi-curved-arrow.svg";
import DashedLineShort from "../../images/case-studies/apw-partners/svg/apw-dashed-short.svg";
import AnimatedDashedLineShort from "../../images/case-studies/apw-partners/svg/apw-dashed-short-animated.svg";
import DashedLineLong from "../../images/case-studies/apw-partners/svg/apw-dashed-long.svg";
import AnimatedDashedLineLong from "../../images/case-studies/apw-partners/svg/apw-dashed-long-animated.svg";
import ContactForm from "../../components/contact-form";
import handsWithFolder from "../../images/case-studies/apw-partners/handsome-creative-APW-partners-folder.png";

const Wrapper = styled.div`
  color: ${mainBlack};
  font-family: ${graphik};
  position: relative;

  .main {
    padding: 0 26px;
    @media ${screen.xsmall} {
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.17;
      margin-bottom: 5px;
      @media ${screen.medium} {
        font-size: clamp(1.1rem, calc(0.88rem + 1vw), 2.1rem);
      }
    }

    &__subheading {
      color: ${mainGray};
      font-size: 1.1rem;
      font-style: italic;
      margin-bottom: 30px;
      @media ${screen.small} {
        margin-bottom: 40px;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
    }

    &__description {
      max-width: 962px;

      p {
        font-size: 1.1rem;
        margin-bottom: 34px;
        @media ${screen.small} {
          font-size: 1.15rem;
          margin-bottom: 40px;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__web-link {
      color: ${mainGray};
      display: inline-block;
      font-size: 1.1rem;
      margin: 0 0 100px 0;
      transition: ${transHover};
      @media ${screen.xsmall} {
        margin: 20px 0 130px 0;
      }
      @media ${screen.small} {
        font-size: 1.15rem;
        margin: 32px 0 175px 0;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }

      @media ${screen.withCursor} {
        &:hover {
          color: #001c38;
        }
      }
    }
  }

  .website {
    padding: 0 26px 210px 26px;
    @media ${screen.xsmall} {
      padding: 0 60px 100px 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }

    &__heading {
      font-weight: 500;
      line-height: 1.17;
      font-size: 2.3rem;
      font-family: ${larsseit};
      margin: 0 auto 20px auto;
      text-align: center;
      max-width: 600px;
      @media ${screen.small} {
        font-size: 2.9rem;
      }
      @media ${screen.medium} {
        font-size: 3rem;
      }
      @media ${screen.xlarge} {
        margin: 0 auto 30px auto;
      }
    }

    &__video-wrapper {
      position: relative;
      max-width: 1100px;
      margin: 135px auto 0 auto;
      width: 100%;
      @media ${screen.xsmall} {
        max-width: 800px;
        margin: 150px auto 0 auto;
      }
      @media ${screen.small} {
        max-width: 840px;
        margin: 0 auto;
      }
      @media ${screen.large} {
        max-width: 1100px;
      }

      video {
        display: block;
        width: calc(100% + 24px);
        margin: 0 0 0 -12px;
        height: 100%;
        outline: none;
        border: none;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        @media ${screen.xsmall} {
          min-width: 520px;
          width: 100%;
          margin: 0;
        }
        @media ${screen.small} {
          min-width: 720px;
        }
      }
    }

    &__pointer-left {
      position: absolute;
      bottom: -105px;
      display: flex;
      left: 40px;
      @media ${screen.xsmall} {
        bottom: -125px;
        left: 50px;
      }
      @media ${screen.small} {
        left: -185px;
        bottom: 170px;
      }

      svg {
        width: 40px;
        transform: rotate(83deg);
        display: block;
        position: absolute;
        bottom: 25px;
        left: 161px;
        @media ${screen.xsmall} {
          transform: rotate(100deg);
          bottom: 30px;
          left: 209px;
        }
        @media ${screen.small} {
          width: 57px;
          bottom: -56px;
          left: 140px;
          transform: rotate(160deg);
        }
      }

      p {
        color: ${mainBlack};
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.3;
        text-transform: uppercase;
        font-family: ${amatic};
        @media ${screen.xsmall} {
          font-size: 1.55rem;
        }
      }
    }

    &__pointer-right {
      position: absolute;
      top: -103px;
      display: flex;
      right: 80px;
      @media ${screen.xsmall} {
        right: 100px;
        top: -124px;
      }
      @media ${screen.small} {
        top: 230px;
        right: -170px;
      }

      svg {
        width: 46px;
        transform: rotate(102deg) scaleX(-1);
        position: absolute;
        left: 134px;
        top: 45px;
        @media ${screen.xsmall} {
          transform: rotate(115deg) scaleX(-1);
          left: 180px;
          top: 60px;
        }
        @media ${screen.small} {
          width: 60px;
          top: -69px;
          left: -30px;
          transform: rotate(-180deg) scale(-1);
        }
      }

      p {
        color: ${mainBlack};
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.3;
        text-transform: uppercase;
        font-family: ${amatic};
        @media ${screen.xsmall} {
          font-size: 1.55rem;
        }
      }
    }
  }

  .responsive {
    display: flex;
    flex-direction: column;
    padding: 0 26px 0 26px;
    position: relative;
    overflow: hidden;
    height: 1082px;
    @media ${screen.xsmall} {
      overflow: visible;
      padding: 150px 60px 150px 60px;
      flex-direction: row;
      height: 1160px;
    }
    @media ${screen.small} {
      padding: 150px 80px;
      height: auto;
    }
    @media ${screen.medium} {
      padding: 150px 7vw;
    }

    &__left-col {
      position: relative;
      @media ${screen.xsmall} {
        padding: 0 40px 0 0;
        width: 100%;
      }
      @media ${screen.small} {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
      }

      .heading {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin: 0 0 30px 0;
        text-align: center;
        @media ${screen.xsmall} {
          margin: 0 0 15px 0;
          text-align: left;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
      }

      .description {
        font-size: 1.1rem;
        margin: 0 0 40px 0;
        text-align: left;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }

        .break {
          @media ${screen.xsmall} {
            display: block;
          }
        }
      }

      .img-device {
        position: absolute;
        width: 40%;
        left: 10px;
        top: 780px;
        @media ${screen.xsmall} {
          left: 0;
          margin: 50px 0 0 0;
          top: auto;
        }
        @media ${screen.small} {
          position: static;
          display: flex;
          justify-content: flex-end;
        }

        &__img {
          display: block;
          width: 100%;
          pointer-events: none;
          max-width: 112px;
          transform: rotate(6deg);
          @media ${screen.xsmall} {
            max-width: 205px;
          }
          @media ${screen.small} {
            margin: 0;
          }
        }

        &__pointer {
          position: absolute;
          left: 0;
          top: -185px;
          @media ${screen.xsmall} {
            left: 40px;
            top: 520px;
          }
          @media ${screen.small} {
            left: calc(1vw + 10px);
            top: 670px;
          }
          @media ${screen.medium} {
            top: 670px;
          }
          @media ${screen.large} {
            left: 180px;
            top: 570px;
          }
          @media ${screen.xlarge} {
            left: 270px;
          }

          p {
            color: ${mainBlack};
            white-space: pre;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.3;
            text-transform: uppercase;
            font-family: ${amatic};
            @media ${screen.xsmall} {
              font-size: 1.55rem;
            }
          }

          svg {
            display: block;
            width: 38px;
            transform: rotate(140deg) scaleX(-1);
            position: absolute;
            left: 91px;
            top: 115px;
            @media ${screen.xsmall} {
              width: 57px;
              top: -96px;
              left: 85px;
              transform: rotate(70deg) scaleX(1);
            }
            @media ${screen.small} {
              transform: rotate(10deg) scaleX(-1);
            }
          }
        }
      }
    }

    &__right-col {
      position: relative;
      position: absolute;
      width: 60%;
      right: 10px;
      top: 535px;
      @media ${screen.xsmall} {
        margin: 50px 0 0 70px;
        top: 350px;
      }
      @media ${screen.small} {
        width: 50%;
        position: static;
      }

      .img-device {
        position: relative;

        &__img {
          display: block;
          width: 260px;
          margin: 0 50px;
          pointer-events: none;
          transform: rotate(-5deg);
          @media ${screen.xsmall} {
            max-width: calc(28vw + 180px);
            width: 100%;
          }
          @media ${screen.small} {
            max-width: 495px;
            margin: 0;
          }
        }

        &__pointer {
          position: absolute;
          bottom: -160px;
          right: 0;
          @media ${screen.xsmall} {
            bottom: -120px;
          }
          @media ${screen.large} {
            right: 200px;
            bottom: -140px;
          }

          p {
            color: ${mainBlack};
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.3;
            text-transform: uppercase;
            font-family: ${amatic};
            @media ${screen.xsmall} {
              font-size: 1.55rem;
            }
          }

          svg {
            display: block;
            width: 46px;
            transform: rotate(130deg) scaleY(-1);
            position: absolute;
            left: 50px;
            top: -64px;
            @media ${screen.xsmall} {
              transform: rotate(75deg) scaleY(-1);
              top: 0;
              width: 60px;
              left: -85px;
            }
          }
        }
      }
    }
  }

  .testimonial {
    position: relative;
    font-family: ${larsseit};
    overflow: hidden;

    &__msg {
      color: ${mainWhite};
      position: absolute;
      top: calc(50% + 30px);
      transform: translateY(-50%);
      left: 26px;
      z-index: 2;
      padding: 0 26px 0 0;
      @media ${screen.xsmall} {
        left: 60px;
        padding: 0;
        top: calc(50% + 50px);
      }
      @media ${screen.small} {
        left: 80px;
      }
      @media ${screen.medium} {
        left: 7vw;
      }

      p {
        line-height: 1.17;
        font-weight: 100;
        font-size: 1.72rem;
        @media ${screen.xsmall} {
          font-size: 2.5rem;
        }
        @media ${screen.small} {
          font-size: 3rem;
        }
        @media ${screen.medium} {
          font-size: clamp(3.5rem, calc(3rem + 1vw), 4.5rem);
        }

        strong {
          font-weight: 700;
        }

        .quotes {
          font-size: 4.5rem;
          position: absolute;
          top: -47px;
          left: 0;
          font-weight: 700;
          @media ${screen.xsmall} {
            font-size: 9.3rem;
            top: -90px;
          }
        }
      }

      .from {
        font-size: 0.85rem;
        font-weight: 100;
        display: block;
        line-height: 1.6;
        font-size: 1rem;
        @media ${screen.xsmall} {
          font-size: 0.88rem;
          font-size: 1.5rem;
          margin-bottom: 4px;
        }
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        @media ${screen.medium} {
          font-size: clamp(1.2rem, calc(0.3rem + 1vw), 1.53rem);
        }

        &--name {
          margin: 35px 0 0 0;
          @media ${screen.xsmall} {
            margin: 80px 0 4px 0;
          }
        }
      }

      .break {
        @media ${screen.xsmall} {
          display: block;
        }
      }
    }

    svg {
      display: block;
      width: 980px;
      /* height: 520px; */
      position: relative;
      left: -300px;
      z-index: 1;
      @media ${screen.xsmall} {
        width: calc(1000px + 50vw);
        height: auto;
        position: static;
      }
    }
  }

  .card-and-book {
    background: #a9a8a8;
    padding: 60px 26px 40px 26px;
    @media ${screen.xsmall} {
      padding: 50px 60px;
    }
    @media ${screen.small} {
      padding: 70px 80px;
    }
    @media ${screen.medium} {
      padding: 200px 7vw 0 7vw;
    }

    &__subwrapper {
      .business-card {
        display: flex;
        flex-direction: column;
        @media ${screen.small} {
          flex-direction: row;
        }

        &__img {
          order: 1;
          @media ${screen.small} {
            order: 0;
            width: 50%;
            padding: 0 80px 0 0;
          }

          .sm {
            display: block;
            width: 100%;
            @media ${screen.small} {
              display: none;
            }
          }

          .lg {
            display: none;
            @media ${screen.small} {
              display: block;
              width: 100%;
              max-width: 600px;
            }
          }
        }

        &__text {
          color: ${mainWhite};
          @media ${screen.small} {
            width: 50%;
          }

          h4 {
            font-weight: 500;
            line-height: 1.17;
            font-size: 2.3rem;
            font-family: ${larsseit};
            margin: 0 0 30px 0;
            @media ${screen.xsmall} {
              margin: 0 0 15px 0;
              max-width: 650px;
            }
            @media ${screen.small} {
              font-size: 2.9rem;
            }
            @media ${screen.medium} {
              font-size: 3rem;
            }
          }

          p {
            font-size: 1.1rem;
            margin: 0 0 40px 0;
            @media ${screen.xsmall} {
              max-width: 750px;
            }
            @media ${screen.small} {
              font-size: 1.15rem;
            }
            @media ${screen.medium} {
              font-size: 1.33rem;
            }
          }
        }
      }

      .book-text {
        color: ${mainWhite};
        margin: 60px 0 0 0;

        h4 {
          font-weight: 500;
          line-height: 1.17;
          font-size: 2.3rem;
          font-family: ${larsseit};
          margin: 0 0 30px 0;
          @media ${screen.xsmall} {
            margin: 0 0 15px 0;
            max-width: 600px;
          }
          @media ${screen.small} {
            font-size: 2.9rem;
          }
          @media ${screen.medium} {
            font-size: 3rem;
          }
        }

        p {
          font-size: 1.1rem;
          @media ${screen.xsmall} {
            max-width: 850px;
          }
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          @media ${screen.medium} {
            font-size: 1.33rem;
          }
        }
      }
    }
  }

  .folder {
    background: #a9a8a8;
    padding: 0 0 0 26px;
    @media ${screen.xsmall} {
      padding: 0 0 0 60px;
    }
    @media ${screen.small} {
      padding: 0;
    }

    &__img-wrapper {
      max-width: 800px;
      position: relative;
      padding: 60px 0 0 0;
      @media ${screen.xsmall} {
        margin: 0 0 0 auto;
        padding: 60px 0 0 0;
      }
      @media ${screen.small} {
        padding: 0;
      }
      @media ${screen.medium} {
        max-width: 1000px;
        padding: 80px 0 0 0;
      }
      @media ${screen.large} {
        max-width: 1140px;
        margin: 0 0 0 auto;
        padding: 30px 0 0 0;
      }
      @media ${screen.xlarge} {
        margin: -60px 0 0 auto;
        padding: 0;
      }

      .pointer-left {
        position: absolute;
        z-index: 4;
        left: 0;
        top: 280px;
        @media ${screen.xsmall} {
          left: -59px;
          top: 410px;
        }
        @media ${screen.small} {
          left: -100px;
        }

        svg {
          width: 40px;
          fill: ${mainWhite};
          position: absolute;
          transform: scaleX(-1) rotate(4deg);
          top: -72px;
          left: 40px;
          @media ${screen.xsmall} {
            top: -72px;
            left: 80px;
            transform: scaleX(-1) rotate(-12deg);
          }
          @media ${screen.small} {
            width: 57px;
            top: -94px;
            left: 90px;
          }
        }

        p {
          color: ${mainWhite};
          font-size: 1.2rem;
          font-weight: 700;
          line-height: 1.3;
          text-transform: uppercase;
          font-family: ${amatic};
          @media ${screen.xsmall} {
            font-size: 1.55rem;
          }
        }
      }

      .pointer-right {
        position: absolute;
        z-index: 4;
        right: 40px;
        top: 0;
        @media ${screen.xsmall} {
          right: 260px;
          top: 0;
        }
        @media ${screen.small} {
          right: 240px;
          top: -60px;
        }
        @media ${screen.medium} {
          right: 350px;
          top: 0;
        }
        @media ${screen.large} {
          right: 400px;
        }
        @media ${screen.xlarge} {
          top: -60px;
        }

        svg {
          width: 40px;
          fill: ${mainWhite};
          position: absolute;
          transform: rotate(-100deg);
          left: -56px;
          top: 16px;
          @media ${screen.xsmall} {
            left: -63px;
            top: 26px;
          }
          @media ${screen.small} {
            width: 57px;
            left: -90px;
            top: 19px;
          }
        }

        p {
          color: ${mainWhite};
          font-size: 1.2rem;
          font-weight: 700;
          line-height: 1.3;
          text-transform: uppercase;
          font-family: ${amatic};
          @media ${screen.xsmall} {
            font-size: 1.55rem;
          }
        }
      }
    }
  }

  .creation {
    position: relative;
    z-index: 20;

    .creation-wrapper {
      padding: 60px 26px;
      @media ${screen.xsmall} {
        padding: 50px 60px;
      }
      @media ${screen.small} {
        padding: 70px 80px;
      }
      @media ${screen.medium} {
        padding: 70px 7vw;
      }
      @media ${screen.large} {
        padding: 90px 7vw;
      }
      @media ${screen.xlarge} {
        padding: 130px 7vw;
      }
    }

    &__text {
      margin: 0 auto;
      max-width: 820px;
      position: relative;
      z-index: 2;

      h4 {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin: 0 0 30px 0;
        @media ${screen.xsmall} {
          margin: 0 0 15px 0;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
          max-width: 750px;
        }
        @media ${screen.medium} {
          font-size: 3rem;
          margin: 0 0 30px -100px;
        }
      }

      p {
        font-size: 1.1rem;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__book {
      .book-wrapper {
        margin: 0 -23px 0 -32px;
        @media ${screen.xsmall} {
          margin: -30px 0 0 -30px;
        }
        @media ${screen.medium} {
          margin: 0 0 0 -30px;
        }

        img {
          display: block;
          width: 100%;
          max-width: 1130px;
          margin: 0 auto;
        }
      }
    }

    .dashed-line-short {
      display: none;
      @media ${screen.small} {
        display: block;
        max-width: 300px;
        height: 300px;
        position: absolute;
        left: 0;
        top: 20px;
      }
      @media ${screen.medium} {
        max-width: 350px;
        height: 350px;
      }
      @media ${screen.large} {
        max-width: 400px;
        height: 400px;
      }

      svg {
        position: absolute;
        top: 20px;
        left: 0;
        @media ${screen.small} {
          width: 300px;
          height: 300px;
        }
        @media ${screen.medium} {
          width: 350px;
          height: 350px;
        }
        @media ${screen.large} {
          width: 400px;
          height: 400px;
        }
      }
    }

    .dashed-line-long {
      display: none;
      @media ${screen.small} {
        max-width: 300px;
        height: 300px;
        position: absolute;
        display: block;
        right: -49px;
        bottom: 50px;
        border: 1px red solid;
      }
      @media ${screen.medium} {
        max-width: 350px;
        height: 350px;
      }
      @media ${screen.large} {
        max-width: 400px;
        height: 400px;
      }

      svg {
        width: 400px;
        height: 400px;
        position: absolute;
        right: -49px;
        bottom: 50px;
        @media ${screen.small} {
          width: 300px;
          height: 300px;
        }
        @media ${screen.medium} {
          width: 350px;
          height: 350px;
        }
        @media ${screen.large} {
          width: 400px;
          height: 400px;
        }
      }
    }
  }

  .next-btn {
    display: flex;
    justify-content: center;
    padding: 0 0 35px 0;
    @media ${screen.small} {
      padding: 0 0 70px 0;
    }
  }
`;

const ApwPartnersPage = ({ data }) => {
  const [isDashedLine1, setIsDashedLine1] = useState(false);
  const [isDashedLine2, setIsDashedLine2] = useState(false);

  const handleDashedLine = () => {
    setIsDashedLine1(true);
    setTimeout(() => {
      setIsDashedLine2(true);
    }, 3550);
  };

  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Layout colourNavbar={mainWhite}>
      <SEO
        title="APW Partners | Handsome Creative"
        description="APW Partners are a boutique wealth investment firm located in
              Melbourne. They tasked Handsome Creative with refreshing their
              brand identity and all of the assets that go with it."
        image={handsWithFolder}
        webPageInfo={{
          name: "APW Partners website by Handsome Creative",
          url,
          description:
            "APW Partners are a boutique wealth investment firm located in Melbourne. They tasked Handsome Creative with refreshing their brand identity and all of the assets that go with it.",
        }}
      />
      <Wrapper>
        <ScrollHint />
        <div className="main">
          <h1 className="main__heading">APW Partners</h1>
          <h6 className="main__subheading">Web design, printed collateral</h6>
          <div className="main__description">
            <p>
              APW Partners are a boutique wealth investment firm located in
              Melbourne. They tasked Handsome Creative with refreshing their
              brand identity and all of the assets that go with it. Using unique
              Australian landmarks, both natural and made, we created a bespoke
              website that is quick loading and looks fantastic on anything you
              view it on.
            </p>
            <p>
              In conjunction with the company website, we designed and produced
              a custom folder and booklet as leave behinds for clients and
              partners. Complimenting the site design, we ensured that APW
              Partner’s brand and values were beautifully presented in all the
              ways their clients engage with them, from business cards to office
              signage to email signatures.
            </p>
          </div>

          <a
            className="main__web-link"
            href="https://apwpartners.com.au"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit company website"
          >
            apwpartners.com.au
          </a>
        </div>

        <section
          className="website"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <h3 className="website__heading">Key outcomes.</h3>
          <div className="website__video-wrapper">
            <video playsInline loop={true} autoPlay="autoplay" muted>
              <source src={websiteVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="website__pointer-left">
              <ArrowCurved />
              <p>
                New website, plus clear and
                <br /> informative sales materials
                <br /> for lead generation and
                <br /> nurturing.
              </p>
            </div>
            <div className="website__pointer-right">
              <ArrowSemiCurved />
              <p>
                New brand vibe and <br />
                design style drawing
                <br /> inspiration from iconic
                <br /> Australian landmarks.
              </p>
            </div>
          </div>
        </section>

        <section className="responsive">
          <div
            className="responsive__left-col"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h2 className="heading">
              APW boosts relationships with partners and customers with brand
              refresh.
            </h2>
            <p className="description">
              Australian families are essential to APW. Every touchpoint is an
              opportunity to show just how much the APW business cares about its
              customers and partners. The team at APW pulled out all the stops
              to ensure this message is reflective in their new brand refresh
              and vibe, sales and marketing tools, and more.
            </p>
            <div className="img-device">
              <div className="img-device__pointer">
                <p>
                  Improved, optimised and
                  <br /> responsive website for
                  <br /> mobile, desktop, and
                  <br /> tablet platforms.
                </p>

                <ArrowCurved />
              </div>
              <div className="img-device__img">
                <StaticImage
                  src="../../images/case-studies/apw-partners/handsome-creative-APW-partners-website-mobile.png"
                  alt="APW responsive website"
                />
              </div>
            </div>
          </div>

          <div
            className="responsive__right-col"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <div className="img-device">
              <div className="img-device__pointer">
                <ArrowSemiCurved />
                <p>
                  Custom design that conveys
                  <br /> the identity and values of
                  <br />
                  APW Partners.
                </p>
              </div>
              <div className="img-device__img">
                <StaticImage
                  src="../../images/case-studies/apw-partners/handsome-creative-APW-partners-website-ipad.png"
                  alt="Custom design that conveys the identity and values of APW Partners."
                />
              </div>
            </div>
          </div>
        </section>

        <section className="testimonial">
          <div
            className="testimonial__msg"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <p>
              The team at Handsome are attentive,
              <span className="break">
                <strong> genuinely care </strong> and provide highly
              </span>{" "}
              personalised service.
              <span className="quotes">“</span>
            </p>
            <span className="from from--name">Rebecca Tai</span>
            <span className="from">APW Partners</span>
          </div>
          <ImgTestimonialBg />
        </section>

        <section className="card-and-book">
          <div className="card-and-book__subwrapper">
            <div className="business-card">
              <div
                className="business-card__img"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <div className="lg">
                  <StaticImage
                    src="../../images/case-studies/apw-partners/handsome-creative-APW-partners-business-cards.png"
                    alt="APW Partners Business card"
                  />
                </div>
                <div className="sm">
                  <StaticImage
                    src="../../images/case-studies/apw-partners/handsome-creative-APW-partners-business-cards-branding.png"
                    alt="APW Partners Business card"
                  />
                </div>
              </div>
              <div
                className="business-card__text"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <h4>
                  Clear value proposition drives more significant customer and
                  partner engagement.
                </h4>
                <p>
                  The Australian wealth investment firm’s new brand style and
                  overall vibe have been a great success: customers and partners
                  quickly understand APW’s value proposition no matter where
                  they are in their wealth journey with the team. APW’s new
                  branding was rolled out by Handsome across all digital
                  channels and assets, sales and marketing material, workspace
                  graphics/signage and more.
                </p>
              </div>
            </div>

            <div
              className="book-text"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <h4>Wealth investment services at your fingertips.</h4>
              <p>
                APW had already proved its ability to offer a variety of wealth
                investment services to the Australian market. For example, the
                team has high success rates for recurring revenue and customer
                retention. Since launching APW’s new responsive website in 2018,
                Sales and Marketing can turn their focus to new business with
                lead generation campaigns. They can also nurture existing
                customers and partners with inspiring content. Together we
                delivered APW’s digital strategy, website launch, and next steps
                for continuous improvement. A smooth, fast loading, and
                professional experience for their customers, partners and
                visitors on any device or browser.
              </p>
            </div>
          </div>
        </section>

        <section className="folder">
          <div
            className="folder__img-wrapper"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <StaticImage
              src="../../images/case-studies/apw-partners/handsome-creative-APW-partners-folder.png"
              alt="APW Partners wealth investment services at your fingertips."
            />

            <div className="pointer-left">
              <ArrowCurved />
              <p>
                It’s Pretty looking and
                <br />
                holds things too!
              </p>
            </div>
            <div className="pointer-right">
              <ArrowCurved />

              <p>
                Created with a custom
                <br />
                dieline, spot and gold foil.
              </p>
            </div>
          </div>
        </section>

        <div className="creation">
          <section className="creation-wrapper">
            {isDashedLine1 && (
              <div className="dashed-line-short">
                <AnimatedDashedLineShort />
                <DashedLineShort />
              </div>
            )}

            {isDashedLine2 && (
              <div className="dashed-line-long">
                <AnimatedDashedLineLong />
                <DashedLineLong />
              </div>
            )}

            <div
              className="creation__text"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <h4>
                Informative sales materials for lead generation and customer
                retention.
              </h4>
              <p>
                Bring it all together. Leveraging the brand refresh strategy and
                the digital tools now in place, APW and Handsome created Sales
                Kits highlighting the businesses value, service offerings, and
                principles. Materials for each stage of the sales cycle from
                lead generation, all the way through to customer retention and
                success.
              </p>
            </div>

            <div
              className="creation__book"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <Waypoint
                onEnter={() => {
                  if (!isDashedLine1) {
                    handleDashedLine();
                  }
                }}
              >
                <div className="book-wrapper">
                  <img
                    src={bookGif}
                    alt="APW Partners informative sales material"
                  />
                </div>
              </Waypoint>
            </div>
          </section>
        </div>

        <div className="next-btn">
          <LabeledArrow
            label="SHOW ME ANOTHER PROJECT"
            url="/our-work/motionthat/"
          />
        </div>
        <ContactForm
          headingText={
            "Want to refresh your brand and connect with your customers in a whole new way? Let’s talk!"
          }
        />
      </Wrapper>
    </Layout>
  );
};

export default ApwPartnersPage;
